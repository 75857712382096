import axios from 'axios';
import { useContext } from 'react';
import { TeamsFxContext } from '../../utils/Context';
import { ClientsApi, OptionsApi, UserApi } from '../api';

// Url
const backendURL = process.env.REACT_APP_BACKEND_URL;

export const useClientOnboardingApi = () => {
    const { teamsUserCredential } = useContext(TeamsFxContext);
    const axiosInstance = axios.create({
        baseURL: backendURL,
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
    });

    // Add auth token
    axiosInstance.interceptors.request.use(async (request) => {
        if (teamsUserCredential) {
            const userInfo = await teamsUserCredential.getUserInfo();
            const token = await teamsUserCredential.getToken('');
            request.headers['Authorization'] = `Bearer ${token?.token}`;
            request.headers['x-user-email'] = userInfo.preferredUserName;
        }
        return request;
    });

    return {
        ClientsApi: new ClientsApi(undefined, backendURL, axiosInstance),
        UserApi: new UserApi(undefined, backendURL, axiosInstance),
        OptionsApi: new OptionsApi(undefined, backendURL, axiosInstance),
    };
};
