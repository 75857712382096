import { Button, makeStyles, Title2 } from '@fluentui/react-components';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { ClientOnboardingFormAtom, DefaultClientOnboardingForm } from '../../store/ClientOnboardingFormAtom';
import ResponsiveRowWrapper from '../layout/ResponsiveRowWrapper';
import Stack from '../layout/Stack';

const useStyles = makeStyles({
    stackRow: {
        justifyContent: 'space-between',
        paddingBottom: '1em',
    },
});

const TopBar = () => {
    const navigate = useNavigate();
    const styles = useStyles();
    const [, setClientOnboardingForm] = useAtom(ClientOnboardingFormAtom);

    const onReturnClick = () => {
        setClientOnboardingForm(DefaultClientOnboardingForm);
        navigate('/Recent');
    };

    return (
        <ResponsiveRowWrapper className={styles.stackRow}>
            <Title2>Client Onboarding Form</Title2>

            <Stack justifyContent="flex-end">
                <Button onClick={onReturnClick}>Return to Client List</Button>
            </Stack>
        </ResponsiveRowWrapper>
    );
};

export default TopBar;
