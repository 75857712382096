import { Card, CardHeader, Divider, makeStyles, Title3 } from '@fluentui/react-components';
import { useFormContext } from 'react-hook-form';
import { ClientOnboardingForm, ClientType } from '../../../api';
import { useLineOfBusinessOptionsGet, useStaffOptionsGet } from '../../../api/hooks/OptionsApi';
import ResponsiveRowWrapper from '../../layout/ResponsiveRowWrapper';
import Stack from '../../layout/Stack';
import ATADSelectInput from '../ATADSelectInput';
import DatePicker from '../DatePicker';
import OptionTypeCombo from '../OptionTypeCombo';
import TextInput from '../TextInput';

const useStyles = makeStyles({
    cardContainer: {
        width: '100%',
    },
});

type AdminCardProps = {
    clientType?: ClientType;
};

const AdminCard = ({ clientType }: AdminCardProps) => {
    console.log('AdminCard', { clientType });

    const styles = useStyles();
    const { control } = useFormContext<ClientOnboardingForm>();
    const showIndividual = clientType === ClientType.Individual || clientType === ClientType.Staff;
    const isStaff = clientType === ClientType.Staff;
    const { data: lineOfBusinessOptions } = useLineOfBusinessOptionsGet();
    const { data: staffOptions, isLoading: staffLoading, isFetched: staffFetched } = useStaffOptionsGet();

    return (
        <Card className={styles.cardContainer}>
            <CardHeader header={<Title3>Admin Information</Title3>} />
            <Stack flexDirection="column">
                <ResponsiveRowWrapper>
                    <ATADSelectInput
                        control={control}
                        name="adminSection.yearEnd"
                        label="Year End"
                        options={[
                            { id: '12/31', name: '12/31' },
                            { id: '03/31', name: '03/31' },
                            { id: '06/30', name: '06/30' },
                            { id: '09/30', name: '09/30' },
                        ]}
                        rules={{ required: true }}
                    />
                    {!showIndividual && (
                        <DatePicker
                            control={control}
                            name="generalSection.businessSection.dateIncorporated"
                            rules={{ required: false }}
                            label="Date of Incorporation"
                        />
                    )}
                </ResponsiveRowWrapper>
                {!isStaff && (
                    <ResponsiveRowWrapper>
                        <ATADSelectInput
                            control={control}
                            name="adminSection.lineOfBusinessId"
                            label="Line of Business"
                            options={lineOfBusinessOptions}
                            rules={{ required: true }}
                        />
                    </ResponsiveRowWrapper>
                )}
                {!isStaff && (
                    <ResponsiveRowWrapper>
                        {staffFetched && (
                            <OptionTypeCombo
                                control={control}
                                name="adminSection.primaryPartnerId"
                                label="Primary Partner"
                                options={staffOptions ?? []}
                                rules={{ required: true }}
                                doneLoading={!staffLoading && staffFetched}
                            />
                        )}
                        {staffFetched && (
                            <OptionTypeCombo
                                control={control}
                                name="adminSection.billerId"
                                label="Biller"
                                options={staffOptions ?? []}
                                rules={{ required: true }}
                                doneLoading={!staffLoading && staffFetched}
                            />
                        )}
                    </ResponsiveRowWrapper>
                )}
                {!isStaff && (
                    <ResponsiveRowWrapper>
                        {staffFetched && (
                            <OptionTypeCombo
                                control={control}
                                name="adminSection.rainmakerId"
                                label="Rainmaker"
                                options={staffOptions ?? []}
                                rules={{ required: true }}
                                doneLoading={!staffLoading && staffFetched}
                            />
                        )}
                    </ResponsiveRowWrapper>
                )}
                {!showIndividual && (
                    <>
                        <Divider alignContent="start" appearance="strong">
                            Additional Business Information
                        </Divider>
                        <ResponsiveRowWrapper>
                            <TextInput
                                control={control}
                                name="generalSection.businessSection.businessCode"
                                label={'Business Code'}
                            />
                            <TextInput
                                control={control}
                                name="generalSection.businessSection.principalBusinessActivity"
                                label={'Principal Business Activity'}
                            />
                        </ResponsiveRowWrapper>
                        <ResponsiveRowWrapper>
                            <TextInput
                                control={control}
                                name="generalSection.businessSection.principalProductService"
                                label={'Principal Product Service'}
                            />
                        </ResponsiveRowWrapper>
                    </>
                )}
            </Stack>
        </Card>
    );
};

export default AdminCard;
