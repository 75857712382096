import {
    FluentProvider,
    Spinner,
    teamsDarkTheme,
    teamsHighContrastTheme,
    teamsLightTheme,
    tokens,
} from '@fluentui/react-components';
import { app } from '@microsoft/teams-js';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import { HashRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import config from '../utils/config';
import { TeamsFxContext } from '../utils/Context';
import ClientSuccess from './routes/ClientSuccess';
import FormPage from './routes/FormPage';
import { RecentClientsTable } from './routes/RecentTable';
import ReviewPage from './routes/ReviewPage';

const queryClient = new QueryClient();

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
    const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
        initiateLoginEndpoint: config.initiateLoginEndpoint!,
        clientId: config.clientId!,
    });

    useEffect(() => {
        loading &&
            app.initialize().then(() => {
                console.log('App initialized');
                app.notifySuccess();
            });
    }, [loading]);

    return (
        <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
            <QueryClientProvider client={queryClient}>
                <FluentProvider
                    theme={
                        themeString === 'dark'
                            ? teamsDarkTheme
                            : themeString === 'contrast'
                              ? teamsHighContrastTheme
                              : {
                                    ...teamsLightTheme,
                                    colorNeutralBackground3: '#eeeeee',
                                }
                    }
                    style={{ background: tokens.colorNeutralBackground3 }}
                >
                    {loading ? (
                        <Spinner style={{ margin: 100 }} size="large" />
                    ) : (
                        <Router>
                            <Routes>
                                <Route path="/FormPage" element={<FormPage />} />
                                <Route path="/ReviewPage" element={<ReviewPage />} />
                                <Route path="/ClientSuccess" element={<ClientSuccess />} />
                                <Route path="/Recent" element={<RecentClientsTable />}></Route>
                                <Route path="*" element={<Navigate to={'/Recent'} />}></Route>
                            </Routes>
                        </Router>
                    )}
                </FluentProvider>
            </QueryClientProvider>
        </TeamsFxContext.Provider>
    );
}
